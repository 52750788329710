/* eslint-disable */
(function ($) {
    'use strict';

    window.scala.documentReady.then(function () {

        // Number incrementation
        var
            rowSelector = ".table tbody tr.main-row",
            rowMobileSelector = '.mobile-visible',
            device = '',
            isMobile = false,
            lastRow = null,

        // identification de l'écran
        getDevice = function(){
            var winSize = $('#site_screen_type').css("font-family");
            winSize = winSize.replace(/"/g,'');
            device = winSize;
        },

        closeAllRow = function( destroy ) {
            destroy = destroy || false;
            $(rowSelector).removeClass('open');
            $(rowMobileSelector).children().not('.hidden-cell').slideUp( 400 );

            if ( destroy ) {
                window.setTimeout( function () { $(rowMobileSelector).remove(); }, 450 );
            }
        },

        expendRow = function( event ) {
            if ( event.currentTarget === lastRow ) {
                lastRow = null;
                return;
            }
            lastRow = event.currentTarget;

            var
                $thisRow = $( event.currentTarget ).parent('.main-row'),
                $row = $('<tr />', {'class': 'mobile-visible'}),
                $colHall = $('<td />'),
                $colStatus = $('<td />', {'class':'mobile-comment'}),
                $colAlert = $thisRow.find('.highlight').clone(true);

            $colHall.html(
                $thisRow.closest('table').find('.th-hall').text()
                + ' '
                + $thisRow.find('.cell-hall').text()
            );
            $colStatus.html( $thisRow.find('.cell-status').text() );
            $colAlert.removeClass('.desk-visible');

            $row.append( $colHall )
                .append( $colStatus )
                .append( $colAlert );

            $thisRow.after( $row );
            $row.children().slideDown();
            $thisRow.addClass('open');
        },

        init = function(){
            getDevice();
            if(device != 'screen-large'){
                isMobile = true;
            };

            $(window).resize( function(){
                getDevice();
                if(device != 'screen-large') {
                    isMobile = true;
                } else {
                    isMobile = false;
                    closeAllRow( true );
                };
            });

            $(document).on('click', '[class^="sorting"]', function (event) {
                closeAllRow( true );
            });
            $(document).on('click', 'td.expend', function(event) {
                if ( !isMobile ) {
                    return;
                }
                event.preventDefault();

                closeAllRow( false );
                expendRow( event );
            });
        };

        init();
    });
}(jQuery.noConflict()));
